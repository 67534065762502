.header-header {
  display: flex;
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0%;
  padding: var(--dl-space-space-unitandhalf);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  margin-bottom: 0.75rem;
  background-color: transparent;
  transition: background-color 0.4s ease-in-out;
  z-index: 1000;
}

.fade-bg {
  background-color: white !important;
}

.header-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-container:first-child > div { margin-right: auto; }

.header-container:last-child  > div { margin-left: auto; }

.header-container-middle {
  width: 50%;
}

.header-container-logo {
  width: 100%;
}
.header-navlink {
  display: flex;
  justify-content: center;
}

.inner-navlink {
  /* flex: 1; */
  display: grid;
  /* /* justify-content: space-evenly; */
  align-items: center;
  /* width: 100%; */
}

/* .inner-navlink-center {
  width: 30%;
} */

.inner-navlink-first { padding-right: 20px; text-align: right; }
.inner-navlink-last { padding-left: 20px }

.header-heading {
  color: var(--dl-color-gray-white);
  font-weight: 700;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
  transition: color 0.4s ease-in-out;
  margin: 0 auto;
  padding-top: 5px;
  width: 100px;
  align-items: center;
  font-size: 1rem;
}

.header-supporting-text {
  font-family: lucida-fax;
  font-weight: 900;
  font-size: 26px;
  text-wrap: nowrap;
  transition: opacity 0.4s ease-in-out;
  padding-top: 5px;
  align-items: center;
  opacity: 0;
  background: var(--mainpart2);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header-logo {
  color: var(--dl-color-gray-white);
  font-weight: 700;
  text-decoration: none;
  margin: 0 auto;
  width: 80px;
  padding-top: 7px;
}
.header-container-links {
  display: flex;
}
.fade-colour {
  color: var(--dl-color-gray-100) !important;
}
.fade-in {
  opacity: 1;
}
.header-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  fill: var(--dl-color-gray-white);
  transition: fill 0.4s ease-in-out;
  width: 100%;
}
.header-icon {
  width: 36px;
  height: 36px;
}
.header-container-socials {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.header-icons {
  fill: var(--dl-color-gray-white);
  width: 30px;
  height: 30px;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
  transition: fill 0.4s ease-in-out;
}
.header-url {
  display: flex;
}
.fade-fill {
  fill: var(--dl-color-gray-100)
}
.header-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  /* display: none; */
  padding: var(--dl-space-space-unitandhalf);
  z-index: -100000;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  display: none;
}
.teleport-show {
  opacity: 1 !important;
  z-index: 100 !important;
}
.header-mobile-menu {
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}
.header-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.header-navlink1 {
  display: contents;
}
.header-heading1 {
  color: var(--dl-color-secondary-400);
  font-weight: 700;
  text-decoration: none;
}
.header-close-menu {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.header-icon10 {
  width: 24px;
  height: 24px;
}
.header-mid {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.header-navlink2 {
  display: contents;
}
.header-container2 {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  flex-direction: row;
  text-decoration: none;
}
.header-icon12 {
  fill: var(--dl-color-secondary-400);
  width: 13px;
}
.header-text {
  color: var(--dl-color-secondary-400);
  margin-left: var(--dl-space-space-halfunit);
}

.header-navlink3 {
  display: contents;
}
.header-container3 {
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
}
.header-icon14 {
  fill: var(--dl-color-secondary-400);
  width: 13px;
}
.header-text1 {
  color: var(--dl-color-secondary-400);
  margin-left: var(--dl-space-space-halfunit);
}
.header-bot {
  flex: 0 0 auto;
  display: flex;
  margin-top: auto;
  align-items: center;
  flex-direction: row;
}
.header-icon19 {
  fill: var(--dl-color-secondary-400);
  width: 24px;
  height: 24px;
  margin-top: var(--dl-space-space-halfunit);
  margin-right: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-icon21 {
  fill: var(--dl-color-secondary-400);
  width: 24px;
  height: 24px;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-icon23 {
  fill: var(--dl-color-secondary-400);
  width: 24px;
  height: 24px;
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: var(--dl-space-space-halfunit);
}
.header-root-class-name {
  margin-bottom: 0px;
}
@media(max-width: 991px) {
  .header-header {
    max-width: 1320px;
    margin-bottom: var(--dl-space-space-triplequarter);
  }
  .header-icon02 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .header-icon19 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .header-icon21 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .header-icon23 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .header-supporting-text {
    display: none;
  }
}
@media(max-width: 767px) {
  /* .header-header {
    background-color: var(--dl-color-gray-white);
  } */
  .header-heading {
    color: var(--dl-color-secondary-400);
  } 
  .header-burger-menu {
    display: flex;
  }
  /* .header-icon {
    fill: var(--dl-color-secondary-400);
  }  */
  .header-container {
    display: none;
  }
  .header-root-class-name {
    margin-bottom: 0px;
  }
}
