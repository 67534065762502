/* @font-face {
  font-family: "TRC-opensans";
  src: url("./fonts/open-sans.ttf");
} */

/* @font-face {
  font-family: "lucida-fax";
  src: url("./fonts/lucida-fax.ttf");
} */

.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
}

.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: -5;
  background-image: url("../../playground_assets/hero-bg.jpg");
}

.home-bg {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 1;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background: linear-gradient(139deg, rgb(216, 199, 140) 0%, rgba(244, 190, 178, 1) 25%, rgba(238, 149, 172, 1) 50%, rgba(182, 125, 173, 1) 75%, rgba(132, 106, 175, 1) 100%);
  mix-blend-mode:hard-light;
  opacity: 0.6;
  background-size: 150% 150%;
  -webkit-animation: gradient-animator 15s ease infinite;
  -moz-animation: gradient-animator 15s ease infinite;
  -o-animation: gradient-animator 15s ease infinite;
  animation: gradient-animator 15s ease infinite;
}

@-webkit-keyframes gradient-animator {
  0%{background-position:0% 5%}
  50%{background-position:100% 96%}
  100%{background-position:0% 5%}
}
@-moz-keyframes gradient-animator {
  0%{background-position:0% 5%}
  50%{background-position:100% 96%}
  100%{background-position:0% 5%}
}
@-o-keyframes gradient-animator {
  0%{background-position:0% 5%}
  50%{background-position:100% 96%}
  100%{background-position:0% 5%}
}
@keyframes gradient-animator {
  0%{background-position:0% 5%}
  50%{background-position:100% 96%}
  100%{background-position:0% 5%}
}

.home-overlay {
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 1;
  opacity: 0.7;
}

.home-container01 {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}

.home-container02 {
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  justify-content: center;
  position: absolute;
  transform:translateY(-58%);
}

.home-text {
  color: var(--dl-color-gray-white);
  text-align: center;
  letter-spacing: 4px;
  font-size: 6vmax;
}

.home-text-spacing {
  letter-spacing: 20px;
}

.home-text01 {
  color: var(--dl-color-gray-900);
  max-width: 600px;
  text-align: center;
  font-size: 1.7vmax;
  letter-spacing: 4px;
}

.home-image {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: -1px;
  position: absolute;
  object-fit: cover;
}

.home-image1 {
  left: auto;
  right: 0px;
  width: 100%;
  bottom: 0px;
  position: absolute;
  object-fit: cover;
}

.home-section1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-image: linear-gradient(135deg, rgb(244, 190, 178) 2.00%, rgb(182, 124, 172) 99.00%);
  clip-path: polygon(0 40px, 100% 0, 100% 100%, 0% 100%);
  margin-top: 100vh;
  z-index: 49;
}

.home-container03 {
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
}

.home-container04 {
  width: 66%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.home-text011 {
  color: #453f58;
  text-align: center;
  padding-bottom: 45px;
  font-size: 90px;
  font-family: "TRC-opensans";
}

.home-text02 {
  color: #453f58;
  text-align: center;
}

.home-text03 {
  color: #000000;
  text-align: center;
  margin-bottom: 0.25rem;
}

.home-cards-container {
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}

.home-card1 {
  flex: 0 0 auto;
  width: 30%;
  min-height: 18em;
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}

.home-container05 {
  flex: 0 0 auto;
  width: 3rem;
  height: 3rem;
  display: flex;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(180deg, #f87171, #dc2626);
}

.home-icon {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}

.home-text04 {
  color: var(--dl-color-secondary-100);
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: "Open Sans";
}

.home-text05 {
  color: var(--dl-color-secondary-500);
  font-size: 1rem;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: "Open Sans";
  font-weight: 500;
  line-height: 1.625rem;
  padding: 0.5rem;
}

.home-card2 {
  flex: 0 0 auto;
  width: 30%;
  min-height: 18em;
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}

.home-container06 {
  flex: 0 0 auto;
  width: 3rem;
  height: 3rem;
  display: flex;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(180deg, #38bdf8, #0284c7);
}

.home-icon02 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}

.home-text06 {
  color: var(--dl-color-secondary-100);
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: "Open Sans";
}

.home-text07 {
  color: var(--dl-color-secondary-500);
  font-size: 1rem;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 1.625rem;
  padding: 0.5rem;
}

.home-card3 {
  flex: 0 0 auto;
  width: 30%;
  min-height: 18em;
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}

.home-container07 {
  flex: 0 0 auto;
  width: 3rem;
  height: 3rem;
  display: flex;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(180deg, #34d399, #059669);
}

.home-icon04 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}

.home-text08 {
  color: var(--dl-color-secondary-100);
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: "Open Sans";
}

.home-text09 {
  color: var(--dl-color-secondary-500);
  font-size: 1rem;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-family: "Open Sans";
  font-weight: 500;
  line-height: 1.625rem;
  padding: 0.5rem;
}

.home-work-with-us {
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: row;
}

.home-container08 {
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  margin-top: -3rem;
  margin-bottom: 2rem;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.home-container09 {
  flex: 0 0 auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}

.home-icon06 {
  fill: var(--dl-color-secondary-500);
  width: 24px;
  height: 24px;
}

.home-text10 {
  color: var(--dl-color-secondary-400);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}

.home-text11 {
  color: var(--dl-color-secondary-500);
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}

.home-text12 {
  color: var(--dl-color-secondary-300);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.home-text14 {
  color: var(--dl-color-secondary-400);
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
}

.home-container10 {
  flex: 0 0 auto;
  width: 33%;
  display: flex;
  position: relative;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-image: linear-gradient(135deg, rgb(249, 238, 177) 1.00%, rgb(243, 184, 177) 100.00%);
}

.home-container11 {
  width: 100%;
  height: 269px;
  display: flex;
  align-items: flex-end;
  border-radius: 0px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url('https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80');
  background-position: center;
  border-top-left-radius: var(--dl-radius-radius-radius50);
  border-top-right-radius: var(--dl-radius-radius-radius50);
  clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);
}

.home-image2 {
  top: auto;
  left: auto;
  width: 100%;
  bottom: 0px;
  object-fit: cover;
  margin-bottom: -1px;
}

.home-container12 {
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}

.home-text15 {
  color: #443b5c;
  font-size: 1.25rem;
}

.home-text18 {
  color: #4b525b;
  margin-top: var(--dl-space-space-unit);
}

.home-image3 {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: -1px;
  position: absolute;
  object-fit: cover;
}

.home-section2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  z-index: 50;
  background: white;
  position: relative;
  clip-path: polygon(0 0, 100% 40px, 100% 100%, 0 100%);
  margin-top: -5%;
}

.home-container13 {
  width: 66%;
  display: flex;
  align-items: center;
  margin-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.home-text21 {
  color: var(--dl-color-secondary-400);
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  line-height: 2.5rem;
  padding-bottom: var(--dl-space-space-unitandhalf);
}

.home-text22 {
  background-color: rgb(255, 255, 255);
}

.home-text24 {
  color: var(--dl-color-secondary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-unitandhalf);
}

.home-growing-company-section {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-image4 {
  width: 33%;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--dl-radius-radius-radius50);
}

.home-container14 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  margin-top: var(--dl-space-space-fiveunits);
}

.home-container15 {
  flex: 0 0 auto;
  width: 4rem;
  height: 4rem;
  display: flex;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-700);
}

.home-icon08 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
}

.home-text25 {
  color: var(--dl-color-secondary-400);
  margin-top: var(--dl-space-space-unitandhalf);
}

.home-text26 {
  color: var(--dl-color-secondary-400);
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unitandhalf);
}

.home-container16 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
}

.home-container17 {
  flex: 0 0 auto;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-700);
}

.home-icon10 {
  fill: var(--dl-color-primary-500);
  width: 20px;
  height: 20px;
}

.home-text27 {
  color: var(--dl-color-secondary-400);
}

.home-container18 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
}

.home-container19 {
  flex: 0 0 auto;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-700);
}

.home-icon12 {
  fill: var(--dl-color-primary-500);
  width: 20px;
  height: 20px;
}

.home-text28 {
  color: var(--dl-color-secondary-400);
}

.home-container20 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
}

.home-container21 {
  flex: 0 0 auto;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-700);
}

.home-icon15 {
  fill: var(--dl-color-primary-500);
  width: 20px;
  height: 20px;
}

.home-text29 {
  color: var(--dl-color-secondary-400);
}

.home-container22 {
  flex: 0 0 auto;
  width: 66%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}

.home-text30 {
  color: rgb(249, 115, 22);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 10px;
}

.home-text31 {
  color: var(--dl-color-secondary-400);
  text-align: center;
}

.home-text32 {
  color: var(--dl-color-secondary-700);
  font-size: 1.25rem;
  text-align: center;
  font-weight: 400;
  margin-bottom: 0.25rem;
  text-transform: none;
  text-decoration: none;
}

.home-text32-subtext {
  color: var(--dl-color-secondary-700);
  text-align: center;
  text-transform: uppercase;
  margin-top: 0.75rem;
}

.home-team {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}

.home-container23 {
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
}

.home-navlink {
  display: contents;
}

.home-container24 {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}

.home-container25 {
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-unitandhalf);
  align-items: flex-start;
  flex-direction: row;
}

.home-container26 {
  flex: 0 0 auto;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #37BCF8;
}

.home-icon17 {
  fill: var(--dl-color-gray-white);
  width: 18px;
  height: 18px;
}

.home-container27 {
  flex: 0 0 auto;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #4aa4e3ff;
}

.home-icon19 {
  fill: var(--dl-color-gray-white);
  width: 18px;
  height: 18px;
}

.home-container28 {
  flex: 0 0 auto;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #EC4999;
}

.home-icon21 {
  fill: var(--dl-color-gray-white);
  width: 18px;
  height: 18px;
}

.home-navlink1 {
  display: contents;
}

.home-container29 {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}

.home-container30 {
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-unitandhalf);
  align-items: flex-start;
  flex-direction: row;
}

.home-container31 {
  flex: 0 0 auto;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #BD195D;
}

.home-icon23 {
  fill: var(--dl-color-gray-white);
  width: 18px;
  height: 18px;
}

.home-container32 {
  flex: 0 0 auto;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 0.25rem;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #4aa4e3ff;
}

.home-icon25 {
  fill: var(--dl-color-gray-white);
  width: 18px;
  height: 18px;
}

.home-section3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-image: linear-gradient(135deg, rgb(164, 113, 156) 0.00%, rgb(132, 106, 175) 100.00%);
  clip-path: polygon(0 40px, 100% 0, 100% 100%, 0% 100%);
  z-index: 51;
}

.home-container33 {
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
}

.home-services {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}

.home-contact {
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}

.home-text33 {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-halfunit);
}

.home-text34 {
  color: rgba(255, 255, 255, 0.75);
  width: 80%;
  text-align: center;
  font-weight: 300;
}

.home-form {
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  padding: var(--dl-space-space-twoandhalf);
  max-width: 700px;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius50);
  flex-direction: column;
  background-color: var(--dl-color-primary-800);
  margin-bottom: var(--dl-size-size-doubleunit);
}

.home-text35 {
  color: var(--dl-color-secondary-400);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

.home-text36 {
  color: var(--dl-color-secondary-400);
}

.home-text37 {
  color: var(--dl-color-secondary-400);
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.25rem;
  margin-bottom: var(--dl-space-space-halfunit);
}

.home-textinput {
  color: rgba(148, 163, 184, 1);
  width: 100%;
  outline: 0;
  padding: var(--dl-space-space-triplequarter);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  ;
  transition: 0.3s;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
}

.home-textinput:focus {
  color: var(--dl-color-secondary-500);
  box-shadow: 0 0 0 2px#4AA4E3;
}

.home-text38 {
  color: var(--dl-color-secondary-400);
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.25rem;
  margin-bottom: var(--dl-space-space-halfunit);
}

.home-textinput1 {
  color: rgba(148, 163, 184, 1);
  width: 100%;
  outline: 0;
  padding: var(--dl-space-space-triplequarter);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  ;
  transition: 0.3s;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
}

.home-textinput1:focus {
  color: var(--dl-color-secondary-500);
  box-shadow: 0 0 0 2px#4AA4E3;
}

.home-text39 {
  color: var(--dl-color-secondary-400);
  margin-top: var(--dl-space-space-halfunit);
  margin-left: 0.25rem;
  margin-bottom: var(--dl-space-space-halfunit);
}

.home-text40 {
  color: var(--dl-color-secondary-100);
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}

.home-textarea {
  color: rgba(148, 163, 184, 1);
  width: 100%;
  outline: 0;
  padding: var(--dl-space-space-triplequarter);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  ;
  transition: 0.3s;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
}

.home-textarea:focus {
  color: var(--dl-color-secondary-500);
  box-shadow: 0 0 0 2px #4AA4E3;
}

.home-container34 {
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}

.home-image5 {
  top: auto;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: -1px;
  position: absolute;
  object-fit: cover;
}

@media(max-width: 991px) {
  .home-cards-container {
    align-items: center;
    flex-direction: column;
  }

  .home-text011 {
    font-size: 75px;
  }

  .home-card1 {
    width: 90%;
    margin-bottom: var(--dl-space-space-fiveunits);
    min-height: auto;
  }

  .home-card2 {
    width: 90%;
    margin-bottom: var(--dl-space-space-fiveunits);
    min-height: auto;
  }

  .home-card3 {
    width: 90%;
    min-height: auto;
  }

  .home-section2 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    margin-top: -7%;
  }

  .home-text21 {
    text-align: center;
  }

  .home-section3 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }

  .home-container33 {
    width: 100%;
  }

  .home-services {
    align-items: center;
    flex-direction: column;
  }
}

@media(max-width: 767px) {
  .home-section1 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
    padding-top: var(--dl-space-space-fiveunits);
  }

  .home-text-spacing {
    letter-spacing: 10px;
  }

  .home-text011 {
    font-size: 65px;
  }

  .home-section2 {
    margin-top: -9%;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }

  .home-container04 {
    width: 100%;
  }

  .home-work-with-us {
    margin-top: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }

  .home-container08 {
    width: 100%;
    margin-bottom: var(--dl-space-space-tripleunit);
    margin-top: 0;
  }

  .home-container10 {
    width: 100%;
  }

  .home-container13 {
    width: 100%;
  }

  .home-growing-company-section {
    flex-direction: column;
  }

  .home-image4 {
    width: 100%;
  }

  .home-text32 {
    font-size: 1rem;
  }

  .home-text40 {
    text-align: center;
  }

  .home-container14 {
    width: 100%;
    margin-top: var(--dl-space-space-fiveunits);
  }

  .home-container22 {
    width: auto;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }

  .home-container23 {
    flex-wrap: wrap;
  }

  .home-container24 {
    width: 50%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }

  .home-container29 {
    width: 50%;
    padding-bottom: var(--dl-space-space-tripleunit);
  }

  .home-form {
    width: 100%;
  }
}

@media(max-width: 479px) {
  .home-container02 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }


  .home-cards-container {
    padding-bottom: 0;
  }

  .home-text011 {
    font-size: 55px;
  }

  .home-section2 {
    margin-top: -15%;
  }

  .home-card1 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }

  /* .home-text05 {
    text-align: left;
  } */

  .home-card2 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }

  /* .home-text07 {
    text-align: left;
  }

  .home-text09 {
    text-align: left;
  } */

  .home-container14 {
    align-items: flex-start;
    /* padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit); */
  }

  .home-container22 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }

  .home-container23 {
    align-items: center;
    flex-direction: column;
  }

  .home-text33 {
    text-align: center;
  }

  .home-text35 {
    text-align: left;
  }
}
