@font-face {
  font-family: "Open Sans";
  src: url("./fonts/open-sans.ttf") format("embedded-truetype");
}

@font-face {
  font-family: "lucida-fax";
  src: url("./fonts/lucida-fax.ttf");
}

:root {
  --dl-color-gray-100: rgba(48, 48, 48);
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #c3c5c9ff;
  --dl-size-size-unit: 1rem;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-space-space-unit: 1rem;
  --dl-color-primary-300: #cc85acff;
  --dl-color-primary-500: #ff9a76ff;
  --dl-color-primary-700: #ecd8d4cf;
  --dl-color-primary-800: #efe9e31a;
  --dl-color-primary-900: #f7d3ceff;
  --dl-size-size-halfunit: 0.5rem;
  --dl-color-secondary-100: #2e1b46ff;
  --dl-color-secondary-300: #261931ff;
  --dl-color-secondary-400: #463355ff;
  --dl-color-secondary-500: #7e678eff;
  --dl-color-secondary-700: #af94b8ff;
  --dl-radius-radius-round: 50%;
  --dl-size-size-doubleunit: 2rem;
  --dl-size-size-tripleunit: 3rem;
  --dl-space-space-halfunit: 0.5rem;
  --dl-space-space-quarterunit: 0.25rem;
  --dl-radius-radius-radius4: 4px;
  --dl-space-space-fiveunits: 5rem;
  --dl-space-space-sixunits: 6rem;
  --dl-radius-radius-radius25: 0.25rem;
  --dl-radius-radius-radius50: 0.5rem;
  --dl-radius-radius-radius75: 0.75rem;
  --dl-space-space-doubleunit: 2rem;
  --dl-space-space-tripleunit: 3rem;
  --dl-space-space-twoandhalf: 2.5rem;
  --dl-space-space-unitandhalf: 1.5rem;
  --dl-space-space-triplequarter: 0.75rem;

  --maingradient: linear-gradient(139deg, rgba(250, 229, 177, 1) 0%, rgba(244, 190, 178, 1) 25%, rgba(238, 149, 172, 1) 50%, rgba(182, 125, 173, 1) 75%, rgba(132, 106, 175, 1) 100%);

  --mainpart1: linear-gradient(139deg, rgba(250, 229, 177, 1) 0%, rgba(244, 190, 178, 1) 25%, rgba(238, 149, 172, 1) 75%, rgba(182, 125, 173, 1) 125%);
  --mainpart2: linear-gradient(139deg, rgba(238, 149, 172, 1) 0%, rgba(182, 125, 173, 1) 50%, rgba(132, 106, 175, 1) 100%);
  --mainpart2latestart: linear-gradient(139deg, rgba(238, 149, 172, 1) 50%, rgba(182, 125, 173, 1) 75%, rgba(132, 106, 175, 1) 100%);

  --gradientlight: linear-gradient(139deg, rgba(250, 229, 177, 0.4) 0%, rgba(244, 190, 178, 0.4) 50%, rgba(238, 149, 172, 0.4) 100%);
  --gradientlightfullopacity: linear-gradient(139deg, rgba(250, 229, 177, 1) 0%, rgba(244, 190, 178, 1) 50%, rgba(238, 149, 172, 1) 100%);
  --gradientdark: linear-gradient(139deg, rgba(238, 149, 172, 1) 0%, rgb(182, 125, 173) 50%, rgb(68, 54, 89) 100%);
  --gradientverydark: linear-gradient(139deg, rgb(90, 57, 66) 0%, rgb(72, 46, 70) 50%, rgb(36, 30, 46) 100%);

}
.fade-appear,
.fade-enter {
opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
opacity: 1;
transition: opacity 400ms;
}

.fade-exit {
opacity: 1;
}

.fade-exit-active {
opacity: 0;
transition: opacity 400ms;
}

.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.button {
  color: var(--dl-color-gray-900);
  display: inline-block;
  padding: 0.5rem 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-900);
  cursor: auto;
  padding: 0.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-900);
  cursor: auto;
  padding: 0.5rem 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.healine {
  font-size: 1.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  text-transform: none;
  text-decoration: none;
}
.textXXL {
  font-size: 1.75rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.textXL {
  font-size: 1.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}
.textSM {
  font-size: 0.87rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: none;
}
.textXS {
  font-size: 0.75rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.content {
  font-size: 1.12rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 300;
  line-height: 1.75;
  text-transform: none;
  text-decoration: none;
}
.textMD {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  line-height: 1.25;
  text-decoration: none;
}
.text2XL {
  font-size: 2.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  text-transform: none;
  text-decoration: none;
}
.text3XL {
  font-size: 3rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}
.textLG {
  font-size: 1.125rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans","Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
}

