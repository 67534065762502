.label-container {
  display: flex;
  position: relative;
}

.label-text {
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  padding-top: 0.25rem;
  padding-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius25);
  padding-right: var(--dl-space-space-halfunit);
  padding-bottom: 0.25rem;
  text-transform: uppercase;
}

.label-color-style1 {
  color: var(--dl-color-primary-500);
  background-color: var(--dl-color-primary-700);
}

.label-color-style2 {
  color: var(--dl-color-secondary-400);
  background-color: var(--dl-color-secondary-700);
}