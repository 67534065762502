.footer-container {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-900);
  z-index: 52;
  clip-path: polygon(0 0, 100% 40px, 100% 100%, 0% 100%);
  margin-top: -5%;
}

.footer-container01 {
  width: 100%;
  max-width: 1320px;
  align-items: center;
  justify-content: space-between;
  margin-top: 6%;
}

.footer-container02 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer-text {
  color: var(--dl-color-secondary-400);
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
  font-weight: 600;
}

.footer-text02 {
  margin-top: var(--dl-space-space-quarterunit);
  margin-bottom: var(--dl-space-space-quarterunit);
  color: var(--dl-color-secondary-300);
  font-size: 1.5rem;
  text-align: center;
}

.footer-text022 {
  margin-top: var(--dl-space-space-quarterunit);
  margin-bottom: var(--dl-space-space-quarterunit);
  color: var(--dl-color-secondary-300);
  font-size: 1.7rem;
  text-align: center;
}

.footer-text03 {
  margin-top: var(--dl-space-space-quarterunit);
  margin-bottom: var(--dl-space-space-quarterunit);
  color: var(--dl-color-secondary-300);
  font-size: 0.75rem;
  text-align: center;
}

.footer-container03 {
  display: flex;
  margin-top: var(--dl-space-space-unitandhalf);
  align-items: flex-start;
  flex-direction: row;
}

.footer-container04 {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}

.footer-icon {
  fill: #37BCF8;
  width: 16px;
  height: 16px;
}

.footer-container05 {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}

.footer-icon2 {
  fill: #ea4c89;
  width: 16px;
  height: 16px;
}

.footer-container06 {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}

.footer-icon4 {
  fill: #4267B2;
  width: 16px;
  height: 16px;
}

.footer-container07 {
  flex: 0 0 auto;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  margin-left: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}

.footer-icon6 {
  width: 16px;
  height: 16px;
}

.footer-container08 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.footer-container09 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: 6rem;
  flex-direction: column;
}

.footer-text03 {
  color: var(--dl-color-secondary-400);
  font-weight: 600;
  padding-bottom: var(--dl-space-space-quarterunit);
}

.footer-text04 {
  color: var(--dl-color-secondary-500);
  padding-bottom: var(--dl-space-space-halfunit);
}

.footer-text05 {
  color: var(--dl-color-secondary-500);
  padding-bottom: var(--dl-space-space-halfunit);
}

.footer-text06 {
  color: var(--dl-color-secondary-500);
}

.footer-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 1px;
  display: flex;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  background-color: #e3e8efff;
}

.footer-text07 {
  color: var(--dl-color-secondary-500);
  align-self: center;
  margin-top: 3%;
}

.footer-link {
  color: var(--dl-color-secondary-400);
  text-decoration: none;
}

@media(max-width: 991px) {
  .footer-container01 {
    align-items: center;
    flex-direction: column;
  }

  .footer-container02 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }

  .footer-container09 {
    align-items: center;
    margin-right: 0px;
  }
}

@media(max-width: 767px) {
  .footer-container {
    margin-top: -8%;
  }

  .footer-text02 {
    text-align: center;
  }

  .footer-container08 {
    align-items: center;
    flex-direction: column;
  }

  .footer-container09 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
}

@media(max-width: 479px) {
  .footer-container {
    margin-top: -12%;
  }
}